import React from "react";

function Loader() {
  return (
    <div>
      <img src="/images/loading1.gif" />
    </div>
  );
}

export default Loader;
